<template>
  <v-row class="flex-column align-center">
    <v-col v-if="signatureNotConfirmed" cols="12" class="mt-10">
      <v-row class="justify-space-between">
        <v-col cols="auto" @click="refreshPage">
          <v-btn color="error" outlined>
            Reiniciar proceso
            <v-icon class="ml-2">mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" @click="checkSignature">
          <v-btn color="success" outlined>
            Volver a intentarlo
            <v-icon class="ml-2">mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-else cols="12" class="text-center flex-grow-1 mt-10">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <p class="subtitle-1">
        {{ returnedFromVidsigner ? "Verificando firma..." : "Redirigiendo..." }}
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    studentVidsignerCode: { type: String, required: true },
    assistanceId: { type: Number, default: 0 },
  },
  data: () => ({
    returnedFromVidsigner: null,
    signatureNotConfirmed: null,
    secondCheckDelay: 1500,
  }),
  mounted() {
    window.addEventListener("focus", this.windowFocus);
    this.openBiometricSignature();
  },
  beforeDestroy() {
    this.disableWindowEvents();
  },
  methods: {
    windowFocus() {
      if (!this.returnedFromVidsigner) this.checkSignature();
      this.returnedFromVidsigner = true;
    },
    disableWindowEvents() {
      window.removeEventListener("focus", this.windowFocus);
    },
    async checkSignature() {
      this.signatureNotConfirmed = null;

      try {
        if (this.assistanceId) {
          const response = await this.checkStudentSignature();

          if (response.data.completed) {
            this.emitFormCompleted();
          } else {
            setTimeout(async () => {
              const response = await this.checkStudentSignature();

              if (response.data.completed) {
                this.emitFormCompleted();
              } else {
                this.signatureNotConfirmed = true;
              }
            }, this.secondCheckDelay);
          }
        } else {
          const response = await this.checkTrainerSignature();

          if (response.data.completed) {
            this.emitFormCompleted();
          } else {
            setTimeout(async () => {
              const response = await this.checkTrainerSignature();

              if (response.data.completed) {
                this.emitFormCompleted();
              } else {
                this.signatureNotConfirmed = true;
              }
            }, this.secondCheckDelay);
          }
        }
      } catch (e) {
        this.$notifyError(undefined, "error al verificar la firma | " + e);
      }
    },
    async checkTrainerSignature() {
      try {
        return await this.$store.state.currentService.checkTrainerSignature(this.$route.params.id);
      } catch (e) {
        this.$notifyError(undefined, "Error al verificar la firma. | " + e);
      }
    },
    async checkStudentSignature() {
      try {
        return await this.$store.state.currentService.checkStudentSignature(
          this.$route.params.id,
          this.assistanceId
        );
      } catch (e) {
        this.$notifyError(undefined, "Error al verificar la firma. | " + e);
      }
    },
    openBiometricSignature() {
      window.location.href = `vidsigner://vidsignerbio/VID_SIGNATURES_USER/${this.studentVidsignerCode}`;
    },
    emitFormCompleted() {
      this.$emit("formCompleted");
    },
    refreshPage() {
      window.location.reload();
    },
  },
};
</script>

<style scoped></style>
